import React, { useEffect, useState } from "react";
import { useGameOverModalStore } from "../stores/useGameOverModalStore";
import { saveHighScore } from "../services/supabase";
import { useScoreStore } from "../stores/useScoreStore";
import { useLoadingStore } from "../stores/useLoadingStore";
import { useLoginModalStore } from "../stores/useLoginModalStore";
import userStore from "../stores/userStore";
import { useScoreSavedModalStore } from "../stores/useScoreSavedModalStore";
import toast from "react-hot-toast";
// import SimplePhaserGameComponent from "./SimplePhaserGameComponent";
import PhaserGameComponent from "./PhaserGameComponent";
import { useProductStatusStore } from "../stores/useProductStatusStore";
import { useProductStore } from "../stores/useProductStore";
import { fetchPointOfSale } from "../services/clickcrate";
import { MdClose } from "react-icons/md";
import MainScene from "./scenes/MainScene";

const PhaserGameWrapper: React.FC = () => {
  const {
    showGameOverModal,
    setShowGameOverModal,
    prizeClaimable,
    setPrizeClaimable,
  } = useGameOverModalStore();
  const score = useScoreStore((state: any) => state.score);
  const { showScoreSavedModal, setShowScoreSavedModal } =
    useScoreSavedModalStore();
  const { canPurchase, setCanPurchase } = useProductStatusStore();
  const { product, setProduct } = useProductStore();
  const [mainScene, setMainScene] = useState<MainScene | null>(null);

  // In PhaserGameWrapper.tsx
  const togglePauseFromReact = () => {
    if (mainScene) {
      mainScene.togglePauseFromReact();
    }
  };

  useEffect(() => {
    const fetchProductData = async () => {
      if (canPurchase) {
        try {
          const productData = await fetchPointOfSale("pos-id");
          setProduct(productData);
        } catch (error) {
          console.error("Error retrieving product:", error);
        }
      }
    };
    fetchProductData();
  }, [canPurchase, setProduct]);

  const saveHighscore = async () => {
    useLoadingStore.getState().setLoadingStatus(true);
    console.log("loading status: ", useLoadingStore.getState().loadingStatus);
    if (
      userStore.getState().solana_wallet_address.trim() === "" ||
      userStore.getState().solana_wallet_address === null
    ) {
      console.log(
        "SWA in saveHighscore is: ",
        userStore.getState().solana_wallet_address
      );
      setShowGameOverModal(false);
      console.log("show modal in saveHighscore is: ", showGameOverModal);
      useScoreStore.getState().setScore(score);
      useLoginModalStore.getState().setShowLoginModal(true);
      console.log(
        "show modal login state: ",
        useLoginModalStore.getState().showLoginModal
      );
    } else {
      if (loadingStatus === true) {
        saveHighScore(score)
          .then(() => {
            useLoadingStore.getState().setLoadingStatus(false);
            setShowScoreSavedModal(true);
            useScoreStore.getState().setScore(0);
          })
          .catch((error) => {
            useLoadingStore.getState().setLoadingStatus(false);
          });
      }
    }
  };

  const claimPrize = async () => {
    useLoadingStore.getState().setLoadingStatus(true);
    console.log("loading status: ", useLoadingStore.getState().loadingStatus);
    if (
      userStore.getState().solana_wallet_address.trim() === "" ||
      userStore.getState().solana_wallet_address === null
    ) {
      console.log(
        "SWA in claimPrize is: ",
        userStore.getState().solana_wallet_address
      );

      setPrizeClaimable(false);
      setShowGameOverModal(true);

      console.log("show modal in claimPrize is: ", showGameOverModal);
      useScoreStore.getState().setScore(score);
      useLoginModalStore.getState().setShowLoginModal(true);
      console.log(
        "show modal login state: ",
        useLoginModalStore.getState().showLoginModal
      );
    } else {
      toast.success("Prize claimed!");
      useProductStatusStore.getState().setCanPurchase(true);
      useLoadingStore.getState().setLoadingStatus(false);
    }
  };

  const purchaseProduct = async () => {
    // useLoadingStore.getState().setLoadingStatus(true);
    // console.log("loading status: ", useLoadingStore.getState().loadingStatus);
    useProductStatusStore.getState().setPurchaseInProgress(true);
    window.open(
      "https://c77256-c7.myshopify.com/products/dogwifhatris-custom-hat",
      "_blank"
    );

    useProductStatusStore.getState().setPurchaseInProgress(true);

    // useLoadingStore.getState().setLoadingStatus(false);

    // if (
    //   userStore.getState().solana_wallet_address.trim() === "" ||
    //   userStore.getState().solana_wallet_address === null
    // ) {
    //   console.log(
    //     "SWA in purchaseProduct is: ",
    //     userStore.getState().solana_wallet_address
    //   );
    //   console.log("show modal in purchaseProduct is: ", showGameOverModal);
    //   useScoreStore.getState().setScore(score);
    //   useLoginModalStore.getState().setShowLoginModal(true);
    //   console.log(
    //     "show modal login state: ",
    //     useLoginModalStore.getState().showLoginModal
    //   );
    // } else {
    //   toast.success("Registered for OraHacks 2024 Airdrop");
    //   useLoadingStore.getState().setLoadingStatus(false);
    // }
  };

  const walletAddress = userStore((state) => state.solana_wallet_address);
  const loadingStatus = useLoadingStore((state) => state.loadingStatus);

  useEffect(() => {
    if (walletAddress.trim() !== "") {
      if (loadingStatus === true) {
        saveHighScore(score)
          .then(() => {
            useLoadingStore.getState().setLoadingStatus(false);
            setShowScoreSavedModal(true);
            toast.success("Score saved!");
          })
          .catch((error) => {
            console.error("Failed to save score: ", error);
            useLoadingStore.getState().setLoadingStatus(false);
            toast.error("Failed to save score!");
          });
      }
    }
  }, [walletAddress, score, loadingStatus, setShowScoreSavedModal]);

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="flex flex-col justify-center items-center h-[440px] font-montserrat">
      {showGameOverModal && (
        <div
          className="fixed inset-0 z-50 flex items-start justify-center"
          onClick={stopPropagation}
        >
          <div
            className="bg-black border-2 border-white rounded-none mt-14 p-4 w-[362px] h-[440px]"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col items-center justify-between h-[100%]">
              {useLoadingStore.getState().loadingStatus ? (
                <div className="flex flex-col items-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                  <p className="text-white mt-2">LOADING</p>
                </div>
              ) : (
                <>
                  <div className="flex flex-col items-center justify-center w-full h-[25%]">
                    <button
                      className="bg-[#665EFF] mb-4 text-white w-full border-2 border-[#665EFF] h-[6vh] rounded-none font-extrabold disabled:opacity-50 hover:bg-[#817df2] hover:border-[#817df2]"
                      disabled={true}
                      onClick={() => claimPrize()}
                    >
                      CLAIM PRIZE
                    </button>
                    <button
                      className="bg-[#0dd353] text-white w-full border-2 border-[#0dd353] h-[6vh] rounded-none font-extrabold disabled:opacity-50 hover:bg-[#04e762] hover:border-[#04e762]"
                      disabled={showScoreSavedModal || score < 1}
                      onClick={saveHighscore}
                    >
                      SAVE HIGHSCORE
                    </button>
                  </div>

                  <button
                    className="bg-[#FF1644] text-white w-full border-2 border-[#FF1644] h-[6vh] mt-12 rounded-none font-extrabold hover:bg-[#ff4769] hover:border-[#ff4769]"
                    onClick={() => {
                      setShowGameOverModal(false);
                      setShowScoreSavedModal(false);
                    }}
                  >
                    CLOSE
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {canPurchase && (
        <div
          className="fixed inset-0 z-50 flex items-start justify-center"
          onClick={stopPropagation}
        >
          <div
            className="bg-black border-2 border-white rounded-none mt-14 p-4 w-[362px] h-[440px]"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col items-center justify-center h-full w-full">
              {useLoadingStore.getState().loadingStatus ? (
                <div className="flex flex-col items-center">
                  <div className="w-6 h-6 border-2 border-white border-t-2 border-t-transparent rounded-full animate-spin"></div>
                  <p className="text-white mt-2 text-sm font-bold">LOADING</p>
                </div>
              ) : (
                <div className="justify-between items-start flex flex-col h-full w-full">
                  {product &&
                  product.variants &&
                  product.variants.edges &&
                  product.variants.edges.length > 0 ? (
                    <div className="text-white mt-2">
                      <img
                        src={product.variants.edges[0].node.image.originalSrc}
                        alt={product.title}
                        className="w-[24px] h-[24px] object-cover mx-auto"
                      />

                      <p>{product.title}</p>
                      <p>Price: {product.variants.edges[0].node.price}</p>
                      <p>Available Sizes:</p>
                      {/* Display available sizes */}
                      <p>Stock Quantity: {/* Display stock quantity */}</p>
                    </div>
                  ) : (
                    <div className="text-white flex flex-col items-center justify-between w-full h-full ">
                      <div className="flex flex-col items-start justify-start w-full h-full">
                        <img
                          src="https://c77256-c7.myshopify.com/cdn/shop/files/il_1588xN.4283886550_rea2.webp?v=1715821532&width=1426"
                          alt="Dogwifhatris Custom Hat"
                          className="w-60 h-60 object-cover"
                        />
                        <p className="text-white mt-2 text-md font-extrabold">
                          Dogwifhatris Custom Hat
                        </p>
                        <p className="text-white text-md mb-2">
                          Price: $19.99 USD
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="flex flex-row items-between justify-center gap-x-4 w-full">
                    <button
                      className="bg-[#FF1644] text-white w-full border-2 border-[#FF1644] h-[6vh] rounded-none font-extrabold hover:bg-[#ff4769] hover:border-[#ff4769]"
                      onClick={() => {
                        setShowGameOverModal(false);
                        setCanPurchase(false);
                        togglePauseFromReact();
                        setShowScoreSavedModal(false);
                      }}
                    >
                      CLOSE
                    </button>
                    <button
                      className="bg-[#665EFF] text-white w-full border-2 border-[#665EFF] h-[6vh] rounded-none font-extrabold disabled:opacity-50 hover:bg-[#817df2] hover:border-[#817df2]"
                      onClick={purchaseProduct}
                    >
                      PURCHASE
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <PhaserGameComponent setMainScene={setMainScene} />
    </div>
  );
};

export default PhaserGameWrapper;
