import Phaser from "phaser";
import { useProductStatusStore } from "../../stores/useProductStatusStore";

export default class MainScene extends Phaser.Scene {
  private characters: Phaser.Physics.Arcade.Sprite[] = [];
  private hats: Phaser.Physics.Arcade.Sprite[] = [];
  private activeHat1: Phaser.Physics.Arcade.Sprite | undefined;
  private activeHat2: Phaser.Physics.Arcade.Sprite | undefined;
  private columns: number = 6;
  private currentColumnPair: number = 2;
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  private score: number = 0;
  private scoreText!: Phaser.GameObjects.Text;
  private touchStart: Phaser.Math.Vector2;
  private touchEnd: Phaser.Math.Vector2;
  private gameOver: boolean = false;
  private paused: boolean = false;
  private pauseButton!: Phaser.GameObjects.Image;
  private level: number = 1;
  private debounceTime: number = 100;
  private scored: boolean = false;
  private hatPurchasable: boolean = false;
  private numOfStacksAchieved: number = 0;

  constructor() {
    super("MainScene");
    this.touchStart = new Phaser.Math.Vector2();
    this.touchEnd = new Phaser.Math.Vector2();
    this.activeHat1 = undefined;
    this.activeHat2 = undefined;
  }

  private hatTypes: { key: string; points: number }[] = [
    { key: "fedora-hat", points: 10 },
    { key: "straw-hat", points: 50 },
    { key: "wif-hat", points: 75 },
    { key: "top-hat", points: 100 },
    { key: "diamond-hat", points: 100 },
    { key: "crown-hat", points: 150 },
  ];

  resetValues() {
    this.score = 0;
    this.gameOver = false;
    this.paused = false;
    this.scored = false;
    this.hats = [];
    this.characters = [];
    this.numOfStacksAchieved = 0;
  }

  preload() {
    this.load.image("character", "../assets/dogwifhat.svg");
    this.load.image("fedora-hat", "../assets/fedora.png");
    this.load.image("straw-hat", "../assets/straw-hat.png");
    this.load.image("wif-hat", "../assets/wif-hat.png");
    this.load.image("top-hat", "../assets/top-hat.png");
    this.load.image("diamond-hat", "../assets/diamond-hat.png");
    this.load.image("crown-hat", "../assets/crown.png");
    this.load.image("pause", "../assets/pause.svg");
    this.load.image("play", "../assets/play.svg");
  }

  create() {
    this.resetValues();
    this.cameras.main.setBackgroundColor("#00ACB5");

    const gridWidth = 360;
    const gridHeight = this.sys.game.config.height as number;
    const cellWidth = gridWidth / 6;
    const cellHeight = 20;
    const numRows = Math.ceil(gridHeight / cellHeight);

    // this.drawGridLines();

    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < 6; col++) {
        const x = col * cellWidth + cellWidth / 2;
        const y = row * cellHeight + cellHeight / 2;

        if (row === numRows - 1) {
          // Position characters in the last row
          const character = this.physics.add.sprite(
            x,
            y,
            "character"
          ) as Phaser.Physics.Arcade.Sprite;
          character.setScale(0.11);
          character.setCollideWorldBounds(true);
          character.setImmovable(true);
          character.body!.setSize(character.width, character.height, true);
          character.body!.setOffset(0, 0);
          character.setOrigin(0.5, 0);
          character.setDepth(0);
          this.characters.push(character);
        }
      }
    }

    this.cursors = this.input.keyboard!.createCursorKeys();

    this.input.on("pointerdown", (pointer: Phaser.Input.Pointer) => {
      this.touchStart.set(pointer.x, pointer.y);
    });

    this.input.on("pointerup", (pointer: Phaser.Input.Pointer) => {
      this.touchEnd.set(pointer.x, pointer.y);
      this.handleSwipe();
    });

    this.scoreText = this.add.text(16, 16, "SCORE: 0", {
      fontSize: "16px",
      color: "#fff",
      fontStyle: "400",
      fontFamily: "Montserrat",
    });

    this.pauseButton = this.add.image(320, 16, "pause");
    this.pauseButton.setScale(0.05);
    this.pauseButton.setDepth(1);
    this.pauseButton.setOrigin(0, 0);
    this.pauseButton.setInteractive();
    this.pauseButton.on("pointerdown", this.togglePause, this);

    this.time.delayedCall(100, this.createHatPair, [], this);

    // this.physics.world.createDebugGraphic();
  }

  update() {
    if (this.paused || this.gameOver) return;

    if (this.activeHat1) {
      const column1 = Math.floor(
        this.activeHat1.x / (this.sys.canvas.width / this.columns)
      );
      const hatsInColumn1 = this.hats.filter((hat) => {
        const c = Math.floor(hat.x / (this.sys.canvas.width / this.columns));
        return c === column1;
      });
      if (hatsInColumn1.length > 2) {
        const topHatInColumn1 = this.getTopmostHatInColumn(column1, [
          this.activeHat1,
        ]);

        if (
          topHatInColumn1 &&
          this.activeHat1.y >= topHatInColumn1.y - 25 &&
          topHatInColumn1.y !== 0
        ) {
          this.stackHats(this.activeHat1, topHatInColumn1);
        }
      }
    }

    if (this.activeHat2) {
      const column2 = Math.floor(
        this.activeHat2.x / (this.sys.canvas.width / this.columns)
      );
      const hatsInColumn2 = this.hats.filter((hat) => {
        const c = Math.floor(hat.x / (this.sys.canvas.width / this.columns));
        return c === column2;
      });
      if (hatsInColumn2.length > 2) {
        const topHatInColumn2 = this.getTopmostHatInColumn(column2, [
          this.activeHat2,
        ]);

        if (
          topHatInColumn2 &&
          this.activeHat2.y >= topHatInColumn2.y - 25 &&
          topHatInColumn2.y !== 0
        ) {
          this.stackHats(this.activeHat2, topHatInColumn2);
        }
      }
    }

    if (this.cursors.left.isDown) {
      this.moveLeft();
    } else if (this.cursors.right.isDown) {
      this.moveRight();
    } else if (this.cursors.down.isDown) {
      this.speedUpHats();
    }
  }

  private lastLeftMove: number = 0;

  moveLeft() {
    if (
      !this.paused &&
      this.currentColumnPair > 0 &&
      this.time.now - this.lastLeftMove > this.debounceTime
    ) {
      const leftColumn = this.currentColumnPair - 1;
      const topHatInLeftColumn = this.getTopmostHatInColumn(leftColumn);

      if (
        !topHatInLeftColumn ||
        (this.activeHat1 && this.activeHat1.y + 40 < topHatInLeftColumn.y) ||
        (this.activeHat2 && this.activeHat2.y + 40 < topHatInLeftColumn.y)
      ) {
        this.currentColumnPair--;
        this.updateHatPosition();
        this.lastLeftMove = this.time.now;
      }
    }
  }

  private lastRightMove: number = 0;

  moveRight() {
    if (
      !this.paused &&
      this.currentColumnPair < this.columns - 2 &&
      this.time.now - this.lastRightMove > this.debounceTime
    ) {
      const rightColumn = this.currentColumnPair + 2;
      const topHatInRightColumn = this.getTopmostHatInColumn(rightColumn);

      if (
        !topHatInRightColumn ||
        (this.activeHat1 && this.activeHat1.y + 40 < topHatInRightColumn.y) ||
        (this.activeHat2 && this.activeHat2.y + 40 < topHatInRightColumn.y)
      ) {
        this.currentColumnPair++;
        this.updateHatPosition();
        this.lastRightMove = this.time.now;
      }
    }
  }

  private lastSwipeTime: number = 0;

  handleSwipe() {
    if (
      !this.paused &&
      this.time.now - this.lastSwipeTime > this.debounceTime
    ) {
      const dx = this.touchEnd.x - this.touchStart.x;
      const dy = this.touchEnd.y - this.touchStart.y;

      if (dx < -50) {
        this.moveLeft();
      } else if (dx > 50) {
        this.moveRight();
      } else if (dy > 50) {
        this.speedUpHats();
      }

      this.lastSwipeTime = this.time.now;
    }
  }

  private lastSpeedUp: number = 0;

  speedUpHats() {
    if (!this.paused && this.time.now - this.lastSpeedUp > this.debounceTime) {
      if (this.activeHat1 && this.activeHat2) {
        this.activeHat1.setVelocityY(1000);
        this.activeHat2.setVelocityY(1000);
      } else if (this.activeHat1 && !this.activeHat2) {
        this.activeHat1.setVelocityY(1000);
      } else if (!this.activeHat1 && this.activeHat2) {
        this.activeHat2.setVelocityY(1000);
      }

      this.lastSpeedUp = this.time.now;
    }
  }

  togglePause() {
    if (this.gameOver) return;

    this.paused = !this.paused;

    if (this.paused) {
      this.physics.pause();
      this.pauseButton.setTexture("play");
    } else {
      this.physics.resume();
      this.pauseButton.setTexture("pause");
    }
  }

  drawGridLines() {
    const gridWidth = 360;
    const gridHeight = this.sys.game.config.height as number;
    const cellWidth = gridWidth / 6;
    const cellHeight = 20;
    const numRows = Math.ceil(gridHeight / cellHeight);

    const graphics = this.add.graphics();
    graphics.lineStyle(1, 0xffffff, 1);

    for (let col = 1; col < 6; col++) {
      const x = col * cellWidth;
      graphics.moveTo(x, 0);
      graphics.lineTo(x, gridHeight);
    }

    for (let row = 1; row < numRows; row++) {
      const y = row * cellHeight;
      graphics.moveTo(0, y);
      graphics.lineTo(gridWidth, y);
    }

    graphics.strokePath();
  }

  createHatPair() {
    this.currentColumnPair = Math.floor(this.columns / 2) - 1;

    const hat1Type = Phaser.Math.RND.pick(this.hatTypes);
    const hat2Type = Phaser.Math.RND.pick(this.hatTypes);

    // const hat1Type = this.hatTypes[0];
    // const hat2Type = this.hatTypes[1];

    const leftColumn = this.currentColumnPair;
    const rightColumn = this.currentColumnPair + 1;

    const cellWidth = 360 / 6;
    // const cellHeight = 20;

    const hat1 = this.physics.add.sprite(
      (leftColumn + 0.5) * cellWidth,
      0,
      hat1Type.key
    ) as Phaser.Physics.Arcade.Sprite;
    const hat2 = this.physics.add.sprite(
      (rightColumn + 0.5) * cellWidth,
      0,
      hat2Type.key
    ) as Phaser.Physics.Arcade.Sprite;

    hat1.body!.setSize(hat1.width, hat1.height, true);
    hat1.body!.setOffset(0, 0);
    hat1.setOrigin(0.5, 0);

    hat2.body!.setSize(hat2.width, hat2.height, true);
    hat2.body!.setOffset(0, 0);
    hat2.setOrigin(0.5, 0);

    const targetWidth = 45;
    const targetHeight = 45;
    const scaleX = targetWidth / hat1.width;
    const scaleY = targetHeight / hat1.height;
    hat1.setScale(scaleX, scaleY);
    hat2.setScale(scaleX, scaleY);

    hat1.setDepth(0);
    hat2.setDepth(0);

    // hat1.setActive(true);
    // hat1.setVisible(true);
    // hat1.body!.enable = true;
    // hat2.setActive(true);
    // hat2.setVisible(true);
    // hat2.body!.enable = true;

    this.physics.world.enableBody(hat1);
    this.physics.world.enableBody(hat2);

    hat1.setCollideWorldBounds(true);
    hat2.setCollideWorldBounds(true);

    hat1.setVelocityY(50);
    hat2.setVelocityY(50);

    if (!hat1.body) {
      console.warn("Hat1 body is undefined or null");
    }

    if (!hat2.body) {
      console.warn("Hat2 body is undefined or null");
    }

    // Store the created hat pair in the activeHatPair array
    this.activeHat1 = hat1;
    this.activeHat2 = hat2;

    const topmostHats = this.getTopmostHatsPerColumn();

    const collidableObjects = [...topmostHats, ...this.characters];

    this.hats.push(hat1, hat2);
    console.log("# of hats after collider & push:", this.hats);

    this.physics.add.collider(
      [this.activeHat1, this.activeHat2],
      collidableObjects,
      (hat, object) => {
        console.log("Collision");
        this.stackHats(
          hat as Phaser.Physics.Arcade.Sprite,
          object as Phaser.Physics.Arcade.Sprite
        );
      },
      undefined,
      this
    );
  }

  stackHats(
    hat: Phaser.Physics.Arcade.Sprite,
    object: Phaser.Physics.Arcade.Sprite
  ) {
    const column = Math.floor(hat.x / (this.sys.canvas.width / this.columns));
    const stackedHats = this.hats
      .filter((h) => {
        const c = Math.floor(h.x / (this.sys.canvas.width / this.columns));
        return c === column && h !== hat;
      })
      .sort((a, b) => a.y - b.y);
    console.log("stackedHats: ", stackedHats.length);

    if (hat.body) {
      console.log("1111 set hat stuff");
      hat.setVelocity(0);
      console.log("111 hat y velocity after: ", hat.body?.velocity);

      console.log("111 hat.body!.enable before: ", hat.body!.enable);
      hat.body!.enable = false;
      console.log("111 hat.body!.enable after: ", hat.body!.enable);

      console.log("111 hat.immovable: ", hat.body?.immovable);

      // console.log("111 object.body!.enable: ", object.body?.enable);
      // object.body!.enable = false;

      // console.log("111 object.immovable: ", object.body?.immovable);
    }

    hat.setData("type", hat.texture.key);
    object.setData("type", object.texture.key);

    const cellHeight = 20;
    const numRows = Math.ceil(
      (this.sys.game.config.height as number) / cellHeight
    );
    const rowIndex = numRows - 1 - stackedHats.length;

    console.log("stackedHats.length - 1: ", stackedHats.length - 1);

    hat.y = rowIndex * cellHeight - 3 * cellHeight;
    hat.body!.y = rowIndex * cellHeight - 3 * cellHeight;

    console.log("hat.y set to: ", hat.y);
    console.log("hat.body!.y set to: ", hat.body!.y);

    if (stackedHats.length > 0 && this.isHatStacked(column)) {
      const clearedLines = this.findAndClearScoredHatStack(column);
      this.updateScore(clearedLines);
      this.numOfStacksAchieved++;
      if (this.numOfStacksAchieved === 2) {
        this.hatPurchasable = true;
        this.purchaseHat();
      }
    }

    if (this.isGameOver()) {
      this.endGame();
    }

    if (this.activeHat1 === hat || this.activeHat2 === hat) {
      if (this.activeHat1 === hat) {
        this.activeHat1 = undefined;
      }
      if (this.activeHat2 === hat) {
        this.activeHat2 = undefined;
      }

      // hat.body!.enable = true;
      // hat.setImmovable(true);
      // if (stackedHats.length > 0) {
      //   object.body!.enable = false;
      // }
      if (!this.activeHat1 && !this.activeHat2) {
        this.time.delayedCall(100, this.createHatPair, [], this);
      }
    }
  }

  getTopmostHatsPerColumn(): Phaser.Physics.Arcade.Sprite[] {
    const topmostHats: Phaser.Physics.Arcade.Sprite[] = [];

    for (let column = 0; column < this.columns; column++) {
      const hatsInColumn = this.hats.filter((hat) => {
        const c = Math.floor(hat.x / (this.sys.canvas.width / this.columns));
        return c === column;
      });

      if (hatsInColumn.length > 0) {
        const topmostHat = hatsInColumn.reduce((prev, current) => {
          return prev.y < current.y ? prev : current;
        });
        topmostHats.push(topmostHat);
      }
    }

    return topmostHats;
  }

  isHatStacked(column: number): boolean {
    const stackedHats = this.hats.filter((h) => {
      const c = Math.floor(h.x / (this.sys.canvas.width / this.columns));
      return c === column;
    });

    if (stackedHats.length >= 5) {
      for (let i = 0; i <= stackedHats.length - 5; i++) {
        const hatType = (
          stackedHats[i] as Phaser.Physics.Arcade.Sprite
        ).getData("type");
        let consecutiveHats = 1;

        for (let j = i + 1; j < i + 5; j++) {
          const currentHatType = (
            stackedHats[j] as Phaser.Physics.Arcade.Sprite
          ).getData("type");
          if (currentHatType === hatType) {
            consecutiveHats++;
          } else {
            break;
          }
        }

        if (consecutiveHats === 5) {
          return true;
        }
      }
    }
    return false;
  }

  findAndClearScoredHatStack(column: number): number {
    const stackedHats = this.hats.filter((h) => {
      const c = Math.floor(h.x / (this.sys.canvas.width / this.columns));
      return c === column;
    });

    let points = 0;

    if (stackedHats.length >= 5) {
      let hatType = "";
      let consecutiveHats = 0;
      let startIndex = 0;

      for (let i = 0; i < stackedHats.length; i++) {
        const currentHatType = (
          stackedHats[i] as Phaser.Physics.Arcade.Sprite
        ).getData("type");

        if (currentHatType === hatType) {
          consecutiveHats++;
        } else {
          hatType = currentHatType;
          consecutiveHats = 1;
          startIndex = i;
        }

        if (consecutiveHats === 5) {
          console.log("HAT LINE CLEARING!!!!");

          const hatTypeData = this.getHatTypeData(hatType);
          if (hatTypeData) {
            points = hatTypeData.points;
          }

          for (let j = startIndex; j < startIndex + 5; j++) {
            const hatToRemove = stackedHats[j] as Phaser.Physics.Arcade.Sprite;
            const index = this.hats.indexOf(hatToRemove);
            if (index !== -1) {
              this.hats.splice(index, 1);
            }
            hatToRemove.destroy();
          }

          stackedHats.splice(startIndex, 5);
          i = startIndex - 1; // Adjust the index to continue checking from the next hat
          consecutiveHats = 0;
        }
      }
    }

    return points;
  }

  updateHatPosition() {
    if (this.activeHat1 && this.activeHat2) {
      const leftColumn = this.currentColumnPair;
      const rightColumn = this.currentColumnPair + 1;

      const cellWidth = 360 / 6;

      this.activeHat1.setPosition(
        (leftColumn + 0.5) * cellWidth,
        this.activeHat1.y
      );
      this.activeHat2.setPosition(
        (rightColumn + 0.5) * cellWidth,
        this.activeHat2.y
      );
    }
  }

  getTopmostHatInColumn(
    column: number,
    excludeHats: Phaser.Physics.Arcade.Sprite[] = []
  ): Phaser.Physics.Arcade.Sprite | undefined {
    const hatsInColumn = this.hats.filter((hat) => {
      const c = Math.floor(hat.x / (this.sys.canvas.width / this.columns));
      return c === column && !excludeHats.includes(hat);
    });

    if (hatsInColumn.length > 0) {
      return hatsInColumn.reduce((prev, current) => {
        return prev.y < current.y ? prev : current;
      });
    }

    return undefined;
  }

  getHatTypeData(hatType: string): { key: string; points: number } | undefined {
    return this.hatTypes.find((ht) => ht.key === hatType);
  }

  updateScore(points: number) {
    this.score += points;
    this.scoreText.setText("SCORE: " + this.score);

    if (this.score >= this.level * 100) {
      this.level++;
    }
  }

  isGameOver(): boolean {
    const topHat = this.hats[this.hats.length - 1];
    if (topHat && topHat.y <= 0 && this.hats.length - 1 > 10) {
      console.log(
        "Game ended with hat #: ",
        this.hats.length - 1,
        "with tophat y: ",
        topHat.y
      );
      return true;
    }
    return false;
  }

  endGame() {
    this.gameOver = true;
    this.physics.pause();
    this.scene.start("GameOverScene", { score: this.score });
  }

  purchaseHat() {
    this.togglePause();
    this.hatPurchasable = true;
    useProductStatusStore.getState().setCanPurchase(true);
  }

  togglePauseFromReact() {
    this.togglePause();
  }
}
