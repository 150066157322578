import React, { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PhaserGameWrapper from "./components/PhaserGameWrapper";

function App() {
  const [authStatus, setAuthStatus] = useState("unauthenticated");

  const handleConnect = async () => {
    try {
      setAuthStatus("authenticated");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-background min-h-screen flex flex-col">
      <div className="min-h-screen flex flex-col justify-center align-center">
        <img
          src="/back3.jpg"
          alt="Arcade Background"
          className="absolute top-0 left-0 w-full h-auto object-cover"
          style={{ height: "100%", zIndex: -1 }}
        />
      </div>

      <Header onConnect={handleConnect} authStatus={authStatus} />
      <PhaserGameWrapper />
      <Footer />
    </div>
  );
}

export default App;
